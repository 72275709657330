<template>
  <div class="collection-tips">
    <div class="block-title">
      催收提醒
      <div class="filter-mode">
        <div :class="type == 1 ? 'active' : ''" @click="type = 1">按金额</div>
        <div :class="type == 2 ? 'active' : ''" @click="type = 2">按数量</div>
      </div>
    </div>
    <div class="tips">
      <Item
        v-for="(item, index) in collStatList"
        :key="index"
        :item="item"
        :type="type"
      />
    </div>
    <van-skeleton v-if="pending" :row="4" />
    <div
      class="none-data"
      v-if="!pending && collStatList && collStatList.length == 0"
    >
      暂无数据
    </div>
  </div>
</template>

<script>
import {
  collectionReminderStatisticsPost,
  dashboardCollectionReminder,
} from "@/api";
import { columnAmount } from "@/utils/tool";

export default {
  name: "CollectionTips",
  components: {
    Item: () => import("./components/Item.vue"),
  },
  data() {
    return {
      pending: false,
      type: 1,
      collStatList: [],

      //
    };
  },
  mounted() {
    this.getStatistics();
  },
  methods: {
    columnAmount,

    // 提醒统计
    async getStatistics() {
      try {
        this.pending = true;

        const data = await dashboardCollectionReminder();
        const list = data.filter((it) => it.status == "Y");

        const promiseArr = list.map((item) =>
          (async () => {
            const o = await collectionReminderStatisticsPost({
              day: item.startDay,
              startDay: item.startDay,
              endDay: item.endDay,
              status: item.collectionReminderType,
            });
            item.amount = o.amount;
            item.count = o.count;
          })()
        );

        await Promise.all(promiseArr);

        this.collStatList = list;

        //
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.pending = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.collection-tips {
  width: 100%;
  background: #f5f7f9;
  height: 100vh;
  .block-title {
    position: sticky;
    left: 0;
    top: 0;
    background-color: #f5f7f9;
  }
  .filter-mode {
    display: flex;
    div {
      font-size: 28px;
      line-height: 28px;
      padding: 0 16px;
      color: #666666;
      font-weight: normal;
      &.active {
        color: #1890ff;
      }
      &:first-child {
        border-right: 1px solid #f2f5f5;
      }
    }
  }
  .tips {
    display: flex;
    padding: 0 24px;
    flex-wrap: wrap;
  }
}

</style>
